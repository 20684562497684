<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="establishmentRoles.establishmentRolesData">
      <div class="row">
        <EstablishmentRoleCard
          v-for="(
            establishmentRole, index
          ) in establishmentRoles.establishmentRolesData"
          :key="index"
          v-on:setEstablishmentRoleData="
            establishmentRoles.establishmentRole.fillData($event)
          "
          :establishmentRole="establishmentRole"
        />
      </div>
      <Pagination
        v-if="!isLoading"
        :paginationData="establishmentRoles.pagination"
        v-on:changePage="changePage"
      />
      <!-- <EstablishmentRoleInfo :establishmentRoleData="establishmentRoleData" /> -->
      <EstablishmentRoleDelete
        :establishmentRole="establishmentRoles.establishmentRole"
        v-on:refresh="getAllEstablishmentRoles()"
      />
    </div>

    <EstablishmentRoleFloatBtns
      :theFilterData="establishmentRoles.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "./../../../utils/constants";
import EstablishmentRoleCard from "@/components/settings/establishmentRoles/EstablishmentRoleCard.vue";
// import EstablishmentRoleInfo from "@/components/settings/establishmentRoles/EstablishmentRoleInfo.vue";
import EstablishmentRoleDelete from "@/components/settings/establishmentRoles/EstablishmentRoleDelete.vue";
import EstablishmentRoleFloatBtns from "@/components/settings/establishmentRoles/EstablishmentRoleFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import EstablishmentRoles from "./../../../models/settings/establishmentRoles/EstablishmentRoles";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EstablishmentRoleCard,
    // EstablishmentRoleInfo,
    EstablishmentRoleDelete,
    EstablishmentRoleFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      establishmentRoles: new EstablishmentRoles(),
    };
  },
  methods: {
    changePage(page) {
      this.establishmentRoles.pagination.selfPage = page;
      this.getAllEstablishmentRoles();
    },
    search(data) {
      this.establishmentRoles.filterData.fillData(data);
      this.establishmentRoles.establishmentRolesData = [];
      this.getAllEstablishmentRoles();
    },
    async getAllEstablishmentRoles() {
      this.isLoading = true;
      try {
        const response =
          await this.establishmentRoles.establishmentRole.getAllEstablishmentRoles(
            this.language,
            this.userAuthorizeToken,
            this.establishmentRoles.pagination,
            this.establishmentRoles.filterData
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.establishmentRoles.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.establishmentRoles.establishmentRolesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.establishmentRoles.establishmentRolesData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.establishmentRoles.establishmentRolesData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.establishmentRoles.establishmentRolesData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllEstablishmentRoles();
  },
};
</script>
