<template>
  <div class="flip-card col-12 col-sm-6 col-md-4 col-lg-3">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <img :src="imgSrc" :onerror="`this.src='${defaultImg}'`" alt="Image" />
        <div class="flip-card-title">
          <h4
            class="cut-1line"
            :title="establishmentRole.establishmentRoleNameCurrent"
          >
            {{ isDataExist(establishmentRole.establishmentRoleNameCurrent) }}
          </h4>
        </div>
      </div>
      <div class="flip-card-back">
        <div class="flip-card-title-top">
          <h6 class="cut-1line">
            {{ isDataExist(establishmentRole.fullCode) }}
          </h6>
        </div>
        <div class="flip-card-back-icons">
          <!-- <button
            v-b-modal.EmployeeInfo
            class="btn p-0"
            :title="$t('info')"
            @click="setEstablishmentRoleData()"
          >
            <img src="@/assets/images/info.svg" class="icon-lg" />
          </button> -->
          <router-link
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeEstablishmentRoles.update
              )
            "
            :to="{
              name: 'EstablishmentRoleEdit',
              params: {
                establishmentRoleToken:
                  establishmentRole.establishmentRoleToken,
              },
            }"
            :title="$t('edit')"
          >
            <img src="@/assets/images/pencil.svg" class="icon-lg" />
          </router-link>
          <button
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeEstablishmentRoles.archive
              )
            "
            v-b-modal.EstablishmentRoleDelete
            class="btn p-0"
            :title="$t('delete')"
            @click="setEstablishmentRoleData()"
          >
            <img src="@/assets/images/trash.svg" class="icon-lg" />
          </button>
        </div>
        <div class="flip-card-title">
          <h4
            class="cut-1line"
            :title="establishmentRole.establishmentRoleNameCurrent"
          >
            {{ isDataExist(establishmentRole.establishmentRoleNameCurrent) }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkPrivilege } from "@/utils/functions";
import { BASE_URL } from "./../../../utils/constants";
import { isDataExist } from "./../../../utils/functions";
import EstablishmentRoleImg from "@/assets/images/establishment-role.svg";

export default {
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      defaultImg: EstablishmentRoleImg,
    };
  },
  props: ["establishmentRole"],
  methods: {
    setEstablishmentRoleData() {
      this.$emit("setEstablishmentRoleData", this.establishmentRole);
    },
    isDataExist,
    checkPrivilege,
  },
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
    imgSrc: function () {
      return BASE_URL + this.establishmentRole.establishmentRoleImagePath;
    },
  },
};
</script>

<style lang="scss"></style>
